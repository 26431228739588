.transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 22;

  .scene {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.blue {
  > g > g:nth-child(1),
  > g > g:nth-child(3) {
    path {
      fill: $color-blue--light;
    }
  }

  > g > g:nth-child(2),
  > g > g:nth-child(4) {
    path {
      fill: darken($color-blue--light, 20);
    }
  }
}

.pink {
  > g > g:nth-child(1),
  > g > g:nth-child(3) {
    path {
      fill: $color-pink--bright;
    }
  }

  > g > g:nth-child(2),
  > g > g:nth-child(4) {
    path {
      fill: darken($color-pink--bright, 20);
    }
  }
}

.green {
  > g > g:nth-child(1),
  > g > g:nth-child(3) {
    path {
      fill: $color-green;
    }
  }

  > g > g:nth-child(2),
  > g > g:nth-child(4) {
    path {
      fill: darken($color-green, 20);
    }
  }
}
